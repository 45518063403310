import React, { useRef, useEffect, forwardRef } from "react";

interface AutoPlaySilentVideoProps {
  className?: string;
  video: string;
  ref?: React.Ref<HTMLVideoElement>;
}

const AutoPlaySilentVideo = forwardRef<HTMLVideoElement, AutoPlaySilentVideoProps>(({ className, video }, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.defaultMuted = true;
    }
  }, []);

  return (
    <video
      className={className}
      ref={(node) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      loop
      muted
      controls
      playsInline
    >
      <source src={video} type="video/mp4" />
    </video>
  );
});

export default AutoPlaySilentVideo;